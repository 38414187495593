.blog-section-container {
  display: flex;
  gap: 30px;
}

.blog-banner-container {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

.blogDesc {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media (min-width: 768px) {
    -webkit-line-clamp: 1;
  }
}
