.root {
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-property: color, opacity;
  @apply p-1 text-black flex items-center
  justify-center font-semibold cursor-pointer
  bg-transparent text-sm;
}

.root:focus {
  @apply outline-none;
}

.icon {
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-property: transform, fill;
}

.icon.loading {
  fill: var(--brand);
}

@screen lg {
  .root {
    @apply p-2;
  }
}
