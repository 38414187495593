.smallBannerOverlay {
  @apply absolute right-0 bottom-0 md:py-14 md:px-20 bg-white/90 text-center rounded-banner w-[250px] items-center flex;
  @media (min-width: 768px) {
    width: 330px;
  }
}

.smallBannerOverlayNinthAvenue {
  @apply absolute w-full left-10 bottom-9 md:left-20 bg-white/90 text-left rounded-banner;
  padding: 16px 29px 16px 26px;
  max-width: 277px;
  @media (min-width: 1024px) {
    max-width: 430px;
    padding: 54px 45px 36px 44px;
  }
}

.bannerButton {
  @apply lg:px-10 lg:py-5 lg:text-2xl lg:tracking-normal;
}

.ninefitButton {
  @apply absolute bottom-[22px];

  & button {
    @apply ring-2 ring-inset ring-brand text-brand font-bold
  }
}