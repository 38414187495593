.root {
  @apply md:px-1.5 md:pb-0 md:-mb-2;
  max-width: 308px;
  @media (min-width: 640px) {
    max-width: 492px;
  }
}

.title {
  @apply text-3xl leading-none font-bold mb-6 mt-4 sm:-mt-7 sm:mb-9 text-center;
  @media (min-width: 640px) {
    margin-bottom: 35px;
  }
}

.itemsWrapper {
  @apply mt-6 mb-6 sm:mb-5 sm:mt-5 flex flex-col gap-y-6;
  @media (min-width: 640px) {
    margin-top: 30px;
    margin-bottom: 34px;
    row-gap: 30px;
  }
}

.items {
  @apply flex flex-row items-center gap-x-5;
}

.imageWrapper {
  @apply relative w-32 h-32 rounded-lg overflow-hidden object-contain;
  min-width: 128px;
  min-height: 132px;
  max-width: 128px;
  max-height: 132px;

  @media (min-width: 640px) {
    min-width: 145px;
    min-height: 132px;
    max-width: 145px;
    max-height: 132px;
  }

  & > img {
    @apply block h-full w-auto mx-auto;
  }
}

.brand {
  @apply uppercase font-semibold;
  font-family: 'Sharp Sans';
  color: #0e3163;
}

.productName {
  @apply mt-1 text-sm overflow-hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.addAllToWishlistButton {
  @apply mt-2.5 w-full font-bold h-12;
  & i {
    @apply inline;
  }
}
